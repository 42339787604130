













































import { Vue, Component } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import { partnerMapper, InterestsPartner } from '@/store/modules/partner'
import { ICellRendererParams } from 'ag-grid-community'
import ActionRenderer from '@/components/ag-grid/renderers/ActionRenderer.vue'
import PartnersAPI from '@/api/partners.ts'
import AddEventInfoPartnerModal from '@/components/partner/modal/AddEventInfoPartnerModal.vue'
import { userMappers } from '@/store/modules/user'
import RouterEventWrapper from '@/mixins/RouterEventWrapper'
import ResolveCompanyId from '@/mixins/ResolveCompanyId'
import AgGridFactory from '@/api/factories/agGridFactory'
import { commonRouteNames, commonRouteURLs } from '@/router/pages/common'

const Mappers = Vue.extend({
  computed: {
    ...partnerMapper.mapState(['participationEvents', 'idPartner']),
    ...userMappers.mapGetters(['userCompany', 'isPartner', 'isPRManager'])
  },
  methods: {
    ...partnerMapper.mapMutations(['setIdPartner', 'setModalInfo']),
    ...partnerMapper.mapActions(['fetchInterestsPartner'])
  }
})

@Component({
  components: { AgGridVue, ActionRenderer, AddEventInfoPartnerModal },
  mixins: [RouterEventWrapper, ResolveCompanyId]
})
export default class PartnerParticipationEvents extends Mappers {
  routerPush!: Function
  getCompanyId!: Function

  private deleteParams: any = {}
  private columnDefs = [
    {
      headerName: 'Название',
      field: 'name',
      autoHeight: true,
      width: 150
    },
    {
      headerName: 'Ссылка на источник',
      field: 'source',
      autoHeight: true,
      width: 150
    },
    {
      headerName: 'Описание',
      field: 'description',
      autoHeight: true,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorParams: {
        cols: '70',
        rows: '5'
      },
      width: 300
    },
    {
      headerName: 'Действия',
      pinned: 'right',
      colId: 'actions',
      cellRendererFramework: 'ActionRenderer',
      cellStyle: 'background: white;',
      maxWidth: 120,
      minWidth: 120,
      editable: false,
      sortable: false,
      filter: false,
      cellRendererParams: {
        onDelete: this.onDelete,
        onClone: this.onClone
      }
    }
  ].map((colDef: any) => ({
    ...colDef,
    headerClass: 'AgGridHeader-wrap'
  }))

  private addClick() {
    this.setModalInfo({
      name: '',
      source: '',
      description: '',
      id: 0
    })
    this.$bvModal.show('addPartnerEventsModal')
  }

  private gridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    rowData: [],
    defaultColDef: {
      ...AgGridFactory.getDefaultGridOptions().defaultColDef,
      filterParams: {
        ...AgGridFactory.getDefaultFilterParams().filterParams,
        filterOptions: ['contains']
      }
    },
    pagination: true,
    paginationPageSize: 15,
    enableCellChangeFlash: true,
    onCellClicked: this.editEvents
  }

  private editEvents(event: any) {
    if (event.colDef.colId !== 'actions') {
      this.setModalInfo(event.data)
      this.$bvModal.show('addPartnerEventsModal')
    }
  }

  private onDelete(params: ICellRendererParams) {
    this.deleteParams = params
    this.$bvModal.show('deleteEventModal')
  }

  private deleteClick() {
    this.participationEvents.events = this.participationEvents.events.filter(
      (i: any, index: any) => index !== this.deleteParams.rowIndex
    )
    if (this.deleteParams.data.id) {
      PartnersAPI.deleteInterestsPartner(
        this.idPartner,
        this.deleteParams.data.id
      )
    }
  }

  private onClone(params: ICellRendererParams) {
    this.participationEvents.events = this.participationEvents.events.concat({
      name: params.data.name,
      source: params.data.source,
      description: params.data.description
    })
    const sendArr: InterestsPartner[] = []
    for (const item of this.participationEvents.events) {
      sendArr.push({
        title: item.name,
        url: item.source,
        info: item.description,
        id: item.id ? item.id : null
      })
    }
    PartnersAPI.changeInterestsPartner(this.idPartner, sendArr).then(
      async () => {
        await this.fetchInterestsPartner()
      }
    )
  }

  private async created() {
    const companyId = this.getCompanyId(true)
    if (!companyId) {
      this.routerPush(commonRouteURLs[commonRouteNames.ErrorRoute])
    }

    if (
      this.isPartner &&
      !this.isPRManager &&
      this.userCompany?.company?.id !== companyId
    ) {
      this.routerPush(commonRouteURLs[commonRouteNames.ErrorRoute])
    }

    this.setIdPartner(companyId)

    await this.fetchInterestsPartner()
  }
}
