


























































import { Component, Vue } from 'vue-property-decorator'
import { partnerMapper, InterestsPartner } from '@/store/modules/partner'
import AwesomeMask from 'awesome-mask'
import PartnersAPI from '@/api/partners'
import { checkSource } from '@/utils/fieldValidation'
import _ from 'lodash'

const Mappers = Vue.extend({
  computed: {
    ...partnerMapper.mapState(['participationEvents', 'idPartner', 'modalInfo'])
  },
  methods: {
    ...partnerMapper.mapActions(['fetchInterestsPartner'])
  }
})

@Component({
  directives: {
    mask: AwesomeMask
  }
})
export default class AddSpecialtiesModal extends Mappers {
  private allFill = false

  private checkSource() {
    return checkSource(this.modalInfo.source)
  }

  private openFunc() {
    this.onChangeField()
  }

  private partnerAddClick() {
    let findObj = this.participationEvents.events.find(
      (el: any) => this.modalInfo.id && el.id === this.modalInfo.id
    )
    if (!_.isNil(findObj)) {
      findObj = this.modalInfo
    } else {
      this.participationEvents.events = this.participationEvents.events.concat(
        this.modalInfo
      )
    }
    const sendArr: InterestsPartner[] = []
    for (const item of this.participationEvents.events) {
      const tmpObj = {
        title: item.name,
        url: item.source,
        info: item.description,
        id: item.id ? item.id : null
      }
      sendArr.push(tmpObj)
    }
    PartnersAPI.changeInterestsPartner(this.idPartner, sendArr).then(
      async () => {
        await this.fetchInterestsPartner()
      }
    )
  }

  private onChangeField() {
    if (
      this.modalInfo.name !== '' &&
      this.modalInfo.description !== '' &&
      this.checkSource()
    ) {
      this.allFill = true
    } else {
      this.allFill = false
    }
  }
}
