




import { Component, Vue } from 'vue-property-decorator'
import PartnerParticipationEvents from '@/components/routes/partner/partner-participation-events/index.vue'

@Component({ components: { PartnerParticipationEvents } })
export default class PartnerParticipationEventsRoute extends Vue {}
